import React from "react";

export default function Skills() {
  return (
    <div>
      <section className="skills" id="skill">
        <h2 className="text-center">
          My <span className="hb">skills</span>
        </h2>
        <div className=" d-lg-flex container mt-5 row-cols-1 row-cols-sm-2 g-2">
          <div>
            <h3>Coding Skills:</h3>
            <ul className="me-2">
            <li className="pb-3">
              <h3>HTML+CSS</h3>
              <div className="box1 mb-4 w-100 ">
                <span className="boxs1"></span>
              </div>
            </li>
            <li className="pb-3">
              <h3>Javascript</h3>
              <div className="box2 mb-4 w-100">
                <span className="boxs2"></span>
              </div>
            </li>
            <li className="pb-3">
              <h3>Reactjs</h3>
              <div className="box3 mb-4 w-100">
                <span className="boxs3"></span>
              </div>
            </li>
            <li className="pb-3">
              <h3>Pyhton+Django</h3>
              <div className=" w-100">
                <span className="boxs4"></span>
              </div>
            </li>
          </ul>
          </div>
          <div>
            <h3 className="ms-2">Professional skills:</h3>
            <ul className="ms-2">
            <li className="pb-3">
              <h3>Web development</h3>
              <div className="box1 mb-4 w-100 ">
                <span className="boxs5"></span>
              </div>
            </li>
            <li className="pb-3">
              <h3>Frontend development</h3>
              <div className="box2 mb-4 w-100">
                <span className="boxs6"></span>
              </div>
            </li>
            <li className="pb-3">
              <h3>Backend development</h3>
              <div className="box3 mb-4 w-100">
                <span className="boxs7"></span>
              </div>
            </li>
            <li className="pb-3">
              <h3>Project Management and Collaboration</h3>
              <div className="w-100">
                <span className="boxs8"></span>
              </div>
            </li>
          </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
