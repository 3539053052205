import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {
  return (
    <div>
      <section className="incolor py-4 ">
        <footer className="container d-lg-flex">
          <div className=""><b>
            <p>Copyright &copy; 2024 by Hamza Shahzad | All Rights Reserved.</p>
          </b></div>
          <form className="d-flex align-item-center ms-lg-auto" role="search">
        <input className="rounded px-2" type="search" placeholder="e-mail" aria-label="Search"></input>
        <button className="incolor h-btn py-2 px-3 ms-3 rounded border" type="submit">Send</button>
      </form>
        </footer>
      </section>
    </div>
  );
}
