// import logo from './logo.svg';
import './App.css';
// import './style.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import MainPage from './MyComponents/MainPage'
import About from './MyComponents/About'
import Career from './MyComponents/Career'
import Skills from './MyComponents/Skills'
import Portfoilo from './MyComponents/Portfoilo'
import Header from './MyComponents/Header'
import Footer from './MyComponents/Footer'

function App() {
  return(
    <div>
      <Header/>
      <MainPage/>
      <About/>
      <Career id="career"/>
      <Skills/>
      <Portfoilo/>
      <Footer/>
    </div>
  )
}

export default App;