import React from "react";
import myImg from "./homeImg.png";
import myResume from "./Hamza-Resume.pdf";

export default function MainPage() {
  return (
    <div className="d-flex">
      <section className="" id="home">
        <div className="d-lg-flex">
          <div className="text-center  text-lg-start">
            <h1>
              Hi, I'm <span className="hb">Hamza Shahzad</span>
            </h1>
            <div className="hb">
              <h3>-Developer web-</h3>
            </div>
            <p>
              Proficient in Django and Python hosting services, delivering
              high-quality web applications and managing the entire software
              development lifecycle. Strong problem-solving abilities with a
              focus on optimizing performance and user experience. Dedicated to
              achieving project objectives and staying updated with the latest
              industry trends.
            </p>

            <div className="banner-btn btn-box">
              <a
                href={myResume}
                className="me-4 text-decoration-none incolor h-btn py-2 px-3 rounded border"
                dowmload
              >
                See my CV
                <span></span>
              </a>
            </div>
            <div className="my-4">
              <a href="https://linkedin.com/in/hamzashahzad26" target="_blank">
                <i
                  className="mx-4 h1 h-link fa-brands fa-linkedin-in"
                  style={{ color: "white" }}
                ></i>
              </a>
              <a href="mailto:hamzashahzad26@gmail.com" target="_blank">
                <i
                  className="mx-4 h1 h-link fa-solid fa-envelope"
                  style={{ color: "white" }}
                ></i>
              </a>
              <a href="https://wa.me/+923392022278" target="_blank">
                <i
                  className="mx-4  h1 h-link fa-brands fa-whatsapp"
                  style={{ color: "white" }}
                ></i>
              </a>
            </div>
          </div>

          <div className="d-none d-lg-block">
            <img
              src={myImg}
              className="img- mx-auto"
              style={{ width: "25vw", opacity: "0.9" }}
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  );
}
