import React from "react";
import AboutImg from "./homeImg.png";

export default function About() {
  return (
    <div>
      <section className="text-center" id="about">
        <h2 className="heading my-5">
          About <span className="hb">me</span>
        </h2>
        <div className="about-img">
          <img src={AboutImg} style={{ height: "150px" }} alt="images"></img>
        </div>

        <div className="about-content">
          <h3 className="hb">Web developer!</h3>
          <p>
            Passionate web developer
            specializing in Django, Python, and React.js, dedicated to creating
            efficient and user-friendly applications. Experienced in the entire
            software development lifecycle with strong problem-solving skills
            and attention to detail. Continuously improving and staying current
            with industry trends to deliver innovative solutions.
          </p>

          <div className="btn-box btns">
            <a
              href="about.html"
              className="incolor text-decoration-none h-btn py-2 px-3 rounded border"
            >
              Seen more
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
