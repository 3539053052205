import React from "react";

export default function Career() {
  return (
    <div>
      <section className="" id="career">
        <h2 className="text-center">
          Education and <span className="hb">professional experience</span>
        </h2>

        <div className=" d-lg-flex mx-auto mt-5 row-cols-1 row-cols-sm-2 g-2">
          <div className="me-lg-3">
            <h3 className="title">Education</h3>
            <div className="border rounded my-4 p-4">
              <div className="">Expected 2026</div>
              <b>
                <h3>Bachelors in Computer Science</h3>
              </b>
              <h6>NFC Institute of Engineering and Fertilizer Research.</h6>
              <p>
                Pursuing a Bachelor's degree in Computer Science with key
                courses in Algorithms, Data Structures, and Database Systems,
                building a strong foundation in software development and
                programming principles.
              </p>
            </div>
            <div className="border rounded my-4 p-4">
              <div className=""> 2020 - 2022</div>
              <b>
                <h3>Intermediate (ICS) </h3>
              </b>
              <h6>Superior College (city campus)</h6>
              <p>
                Completed Intermediate in Computer Science (ICS), gaining
                foundational knowledge in programming, databases, and computer
                systems.
              </p>
            </div>
            <div className="border rounded my-4 p-4">
              <div className=""> 2018 - 2020</div>
              <b>
                <h3>Matriculation</h3>
              </b>
              <h6>M.C Higer Secandary School</h6>
              <p>
                Matriculated with a focus on Computer Science, alongside
                rigorous studies in Mathematics, Physics, and Chemistry.
              </p>
            </div>
          </div>

          <div className="ms-lg-3 mx-auto">
            <h3 className="title">Experience</h3>
            <div className="border rounded my-4 p-4">
              <div className=""> 2023 </div>
              <b>
                <h3>DevXhouse l Pakistan </h3>
              </b>
              <h6>Python Web Developer</h6>
              <p>
                <li>Developed and maintained web applications using Django</li>
                <li>
                  Implemented RESTful APIs and integrated third-party services
                  to enhance application functionality
                </li>
                <li>
                  Implemented responsive and user-friendly web interfaces using
                  HTML, CSS, and JavaScript to enhance user experience and
                  engagement
                </li>
                <li>
                  I worked closely with backend developers to integrate APIs and
                  ensure seamless data flow
                </li>
              </p>
              <p>
                The National Agency for Adult Vocational Training (AFPA).
                Marseilles.
              </p>
            </div>

            {/* <div className="border rounded my-4 p-4">
              <b>
                <h3>Projects </h3>
              </b>
              <h6>Company Portfolio</h6>
              <p>
                A dynamic company portfolio website to highlight services,
                projects, and team profiles, significantly improving the
                company's online visibility and client interaction.
              </p>
              <h6>Grammer checker</h6>
              <p>
                Implemented a grammar checker tool to enhance the accuracy and
                quality of written content, improving overall communication and
                reducing errors
              </p>
              <h6>Social media video downloader</h6>
              <p>
                Enables users to download videos from various social media
                platforms, ensuring a seamless and user-friendly experience
              </p>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
