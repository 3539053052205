import React from "react";
import Pimg from "./portfolio.jpg";

const Portfoilo = () => {
  return (
    <div>
    <section className="album" id="projects">
      <div className="text-center py-5">
        <h2>My <span className="hb">Projects</span></h2>
      </div>
      <div className="row container m-auto row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
        
        <div className="zoom-effect text-center my-3 ">
          <img src={Pimg} loading="lazy" alt="img"></img>
        </div>
        
        <div className="zoom-effect text-center my-3">
          <img src={Pimg} loading="lazy" alt="image"></img>
        </div>
        
        <div className="zoom-effect text-center my-3">
          <img src={Pimg} alt=""></img>
        </div>

      </div>
      </section>
    </div>
  );
};

export default Portfoilo;
